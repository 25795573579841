import React from 'react'
import { compose } from 'redux'
import {
	reduxForm,
	Field,
	FieldArray,
	propTypes as formPropTypes,
} from 'redux-form'
import PropTypes from 'prop-types'
import { modifyProps } from 'lp-hoc'
import { push } from 'react-router-redux'
import { Textarea, SubmitButton } from 'lp-components'
import { VotingCallout } from '../components'
import { SearchableSelect } from 'components'
import { isEmpty, uniq, flatMap } from 'lodash'
import confetti from 'canvas-confetti'

const propTypes = {
	...formPropTypes,
	users: PropTypes.array,
	vote: PropTypes.object,
	isRequired: PropTypes.func,
	allowMoreVotes: PropTypes.bool,
}

const starConfetti = () => {
	confetti({
		particleCount: 40,
		shapes: ['star'],
		spread: 160,
		colors: ['F2C32B'],
		scalar: 4,
		gravity: 2,
		disableForReducedMotion: true,
	})
}

const RenderVotes = ({
	fields,
	userOptions,
	valueOptions,
	valueTerm,
	allowMoreVotes,
	isRequired,
	meta: { touched, error, submitFailed },
}) => {
	const canRemove = fields.length > 1
	const selectedUserIds = flatMap(fields.getAll(), 'userIds')

	return (
		<ul className="kudos-list">
			{fields.map((userVote, index) => {
				const field = fields.get(index)
				const remainingUserOptions = userOptions.filter((option) => {
					return !selectedUserIds.includes(option.value)
				})

				return (
					<li key={field.id}>
						<header>
							<h3>Kudos #{index + 1}</h3>
							{canRemove && (
								<button
									type="button"
									onClick={() => fields.remove(index)}
									className="remove-x"
									aria-label={`Remove kudos #${index + 1} vote`}
								>
									×
								</button>
							)}
						</header>
						<Field
							name={`${userVote}.userIds`}
							component={SearchableSelect}
							options={remainingUserOptions}
							label="Employee(s) of the Week"
							placeholder="Select Employee"
							noOptionsMessage={({ inputValue }) =>
								inputValue
									? 'No options'
									: "You've given all the kudos you can! Kudos to YOU!"
							}
							validate={isRequired}
							isMulti={true}
							openMenuOnFocus={true}
						/>
						<Field
							name={`${userVote}.valueId`}
							component={SearchableSelect}
							options={valueOptions}
							label={`Did they demonstrate a specific ${valueTerm}?`}
							placeholder={`Select ${valueTerm}`}
							hint="(optional)"
							openMenuOnFocus={true}
							isClearable={true}
						/>
						<Field
							name={`${userVote}.comment`}
							component={Textarea}
							label="Why they deserve this honor?"
							showCharacterCount={false}
							hint="(optional)"
						/>
					</li>
				)
			})}
			{allowMoreVotes && (
				<div>
					<button
						type="button"
						className="button button-small button-secondary-outline"
						onClick={() => {
							starConfetti()
							fields.push({ id: window.crypto.randomUUID() })
						}}
						aria-label="Add more kudos"
					>
						+ More Kudos
					</button>
					{(touched || submitFailed) && error && <span>{error}</span>}
				</div>
			)}
			<br />
		</ul>
	)
}

function VoteForm({
	handleSubmit,
	pristine,
	submitting,
	invalid,
	users,
	vote,
	allowMoreVotes,
	isRequired,
	error,
}) {
	const valueOptions = vote.organizationValues.map((v) => ({
		label: v.name,
		value: v.id,
	}))
	const userOptions = users.map((user) => ({
		label: user.fullName,
		value: user.id,
	}))
	return (
		<div>
			<VotingCallout
				title="It's Voting Time"
				body={`Hi ${vote.user.firstName}, which of your coworkers did a lovely job last week?`}
			/>
			<div className="voting-card">
				<form onSubmit={handleSubmit} className="voting-form">
					<h2>Cast Your Vote</h2>
					<FieldArray
						name="votes"
						component={RenderVotes}
						userOptions={userOptions}
						valueOptions={valueOptions}
						valueTerm={vote.organizationValueTerminology}
						allowMoreVotes={allowMoreVotes}
						isRequired={isRequired}
					/>
					{error && <span className="form-wide-error">{error}</span>}
					<SubmitButton
						invalid={invalid}
						pristine={pristine}
						submitting={submitting}
						className="button-primary"
					>
						Cast Your Vote
					</SubmitButton>
				</form>
			</div>
		</div>
	)
}

function modify({ vote, token }) {
	return {
		onSubmitSuccess: (response, dispatch) => {
			return dispatch(
				push(
					`/organizations/${vote.organizationId}/votes/${vote.id}/thank-you?token=${token}`
				)
			)
		},
		isRequired: (value) => (!isEmpty(value) ? undefined : 'Required'),
	}
}

VoteForm.propTypes = propTypes

export default compose(
	modifyProps(modify),
	reduxForm({
		form: 'vote-form',
		validate: ({ votes }) => {
			if (isEmpty(votes)) return { _error: 'Must vote for at least 1 coworker' }

			const selectedVoteIds = flatMap(votes, 'userIds').filter(Boolean)
			const uniqueVoteIds = uniq(selectedVoteIds)
			if (uniqueVoteIds.length !== selectedVoteIds.length)
				return { _error: 'Can only vote for each coworker once' }
		},
	})
)(VoteForm)
